<template>
  <div>
    <div v-if="loading" class="d-flex flex-column align-center justify-center">
      <v-progress-linear indeterminate color="info" />
      <h3>Carregando avaliações</h3>
    </div>
    <template v-else>
      <div
        v-if="listEvaluations == null || listEvaluations.length < 1"
        class="d-flex align-center justify-center"
      >
        <v-chip class="my-3" color="success">
          <v-icon class="mr-2">mdi-check</v-icon> Sem avaliação no momento.
        </v-chip>
      </div>
      <div v-else>
        <template v-for="(avaliacao, key) in listEvaluations.avaliacoes">
          <div
            :key="key"
            v-if="avaliacao && avaliacao.length > 0"
            class="d-flex flex-column my-2"
          >
            <h3>
              {{ keyNames[key].text }}
            </h3>
            <v-list subheader two-line>
              <v-list-item
                v-for="evaluation in avaliacao"
                :key="evaluation.uuid"
              >
                <v-list-item-content>
                  <v-list-item-title class="black--text font-weight-regular">
                    {{
                      key == "autoavaliacao"
                        ? "Autoavaliação"
                        : `Avaliação de ${evaluation.nome}`
                    }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    color="info"
                    small
                    :to="
                      redirectToEvaluation(
                        keyNames[key].value,
                        evaluation.uuid_formulario,
                        listEvaluations.uuid,
                        evaluation.uuid
                      )
                    "
                    link
                    target="_blank"
                  >
                    Ir para a avaliação
                    <v-icon class="ml-2">mdi-flag-checkered</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ListaAvaliacoes",
  data() {
    return {
      keyNames: {
        autoavaliacao: { text: "Autoavaliação", value: "aa" },
        avaliacao_pares: { text: "Avaliação de Pares", value: "ap" },
        avaliacao_subordinados: {
          text: "Avaliação de Subordinados",
          value: "as",
        },
        avaliacao_lider: { text: "Avaliação de Líder", value: "al" },
      },

      loading: true,
    };
  },
  created() {
    const uuidUser = this.$route.params.uuid;

    this.$store
      .dispatch("avdFormularios/getFormsOfCollaborator", uuidUser)
      .catch(() => {
        this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
          visibilidade: true,
          mensagem:
            "Ocorreu um erro ao buscar por suas avaliações. Por favor, tente novamente mais tarde.",
          cor: "red",
        });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    redirectToEvaluation(
      typeForm = String,
      uuidForm = String,
      uuidCollaborator = String,
      uuidCollaboratorAvaliable = null
    ) {
      let params = {
        type_form: typeForm,
        uuid_form: uuidForm,
        uuid_collaborator: uuidCollaborator,
        uuid_collaborator_avaliable: uuidCollaboratorAvaliable,
      };

      if (typeForm == "aa") {
        delete params.uuid_collaborator_avaliable;
      }

      return {
        name: "avd-avaliacao-ext",
        params: params,
      };
    },
  },
  computed: {
    listEvaluations: {
      get() {
        return this.$store.getters["avdFormularios/getFormsOfCollaborator"];
      },
    },
  },
};
</script>

<style></style>
